import { mapActions, mapState, } from 'vuex'
import  { myMixin } from '../../../mixins';

export default {
    mixins: [myMixin],
    data: () => ({
    message: '',
    error: false,
    errors: [],
    panelGroup: 0,
    panelAmbience: 0,
    itemSearhStart: null,
    loadingSaving: false,
    selectedCampus: {},
    loadingFilterGroups: false,
    loadingFilterAmbiences: false,
    multipleCampus: false,
    autoSearch: false,
    selection: [],
    showJoin: false,
    itemJoin: null,
    loadingJoins: false,
    level: -1,
    item_id: 0,
    campuses: [],
    campusesAmbiences: [],
    shifts: [],
    courses: [],
    workLoads: [],
    categories: [],
    types: [],
    onlyPending: false,
    ambienceGridTypes: [],
    blocks: [],
    floors: [],
    searchGroup: null,
    searchAmbience: null,
    loading: false,
    renderComponent: true,
    showFilterPrints: false,
    showFormSearch: false,
    group: null,
    groups: [],
    ambience: null,
    ambiences: [],
    itemDrop: null,
    validation: {},
    config: null,
    colors: ['error', 'success', 'primary', 'purple', 'pink', 'cyan', 'indigo', 'blue', 'teal', 'amber', 'blue-grey', 'amber'],
    }),
    
    computed: {
      ...mapState('auth', ['acesso']),
      
      students: function() {
        
        if(!this.group || !this.group.classes){
          return 0;
        }

        var max = this.group.classes[0].grid.students_join;

        this.group.classes.forEach(c => {

          if(c.grid.students_join > max){
            max = c.grid.students_join;
          }

        });

        return max;

      },

      studentsForecast: function() {
        
        if(!this.group || !this.group.classes){
          return 0;
        }

        var max = this.group.classes[0].grid.students_join_forecast;

        this.group.classes.forEach(c => {

          if(c.grid.students_join_forecast > max){
            max = c.grid.students_join_forecast;
          }

        });

        return max;

      },
      
      enableFilterGroup: function() {
        return (this.campuses && this.campuses.length !== 0) && this.shifts.length > 0 && this.workLoads.length > 0 && this.courses.length > 0;

      },

      enableFilterAmbience: function() {
        return (this.campusesAmbiences && this.campusesAmbiences.length !== 0) && this.types.length > 0 && this.floors.length > 0;
      },

      searchStart(){
        return this.itemSearhStart;
      },

      ambiencesFiltered(){

        if(this.searchAmbience){
          return this.ambiences.filter(a => a.name.toUpperCase().includes(this.searchAmbience.toUpperCase()));
        }else{
          return this.ambiences;
        }

      },
  },

  methods: {
      ...mapActions('group', ['ActionFindGroups']),
      ...mapActions('grid', ['ActionFindGrids']),
      ...mapActions('schedule', ['ActionFindClasses']),
      ...mapActions('ambience', ['ActionFindAmbiences']),
      ...mapActions('roomAllocation', ['ActionUpdateAmbienceLink', 'ActionDeleteAmbienceLink', 'ActionFindClassesOptimized']),
      ...mapActions('indication', ['ActionGetGridJoin']),

    removeAmbience(item){

      if(this.loadingSaving){
        return;
      }
      
      var payload = {
        id: item.id
      };

      this.loadingSaving = true;

      var itemDrop = {};
      itemDrop.level = this.level;
      itemDrop.id = this.item_id;

      this.ActionDeleteAmbienceLink(payload)
        .then((res) => {
          item.ambience_id = null;
        }).finally(() => {
          this.loadingSaving = false;
          this.loadClassesAmbience(this.ambience);
          this.selectGroup(itemDrop);
          this.forceRerender();
        });
    },

    start(ev, item){
      this.itemDrop = item;
    },

    end(ev){
      //this.itemDrop = null;
    },

    drop(){
      
      if(!this.itemDrop || !this.ambience){
        return;
      }

      var payload = {
        id:       this.ambience.id,
        item_id:  this.itemDrop.id,
        config:   this.config
      };

      switch (this.itemDrop.level) {
        case 0:
          payload.type = 'group';
          payload.workLoads = this.workLoads.map(o => o['id']);
          payload.ambienceGridTypes = this.ambienceGridTypes.map(o => o['id']);
          break;
        case 1:
          payload.type = 'grid';
          break;
        case 2:
          payload.type = 'classe';
          break;
      }

      var itemDrop = {};
      itemDrop.level = this.itemDrop.level;
      itemDrop.id = this.itemDrop.id;

      this.updateAmbience(payload, itemDrop);

    },

    updateAmbience(payload, itemDrop = null){
      
      this.error = false;
      this.errors = [];
      this.loadingSaving = true;
      
      this.ActionUpdateAmbienceLink(payload)
        .then((res) => {

        }).catch((error) => {
          
          this.error = true;
          this.errors =   error.errors;

        }).finally(() => {
          
          this.loadingSaving = false;
          this.loadClassesAmbience(this.ambience);
          this.selectGroup(itemDrop);
          this.forceRerender();

        });
    },

    filterGroups(){
      
      this.groups = [];
      this.group = null;
      this.searchGroup = null;
      this.loadingFilterGroups = true;

      let campus_id;

      if(this.multipleCampus){
        campus_id = this.campuses.map(o => o['id']).join(',');
      }else{
        campus_id = this.campuses;
      }

      var payload = { 
        with: 'course',
        column_order: 'name',
        find_columns: {
          campus_id: campus_id,
          status_group_id: 1,
        },
        filter_relations: [
          {
              name: 'shift',
              key: 'id', 
              value: this.shifts.map(o => o['id']).join(','),
              clause: 'whereIn',
          },
          {
            name: 'course',
            key: 'id', 
            value: this.courses.map(o => o['id']).join(','),
            clause: 'whereIn',
          },
          {
            name: 'grids',
            key: 'has_ambience',
            value: 1,
            innerRelations: [
              { 
                name: 'ambienceType',
                key: 'allow_ambience_link',
                value: 1,
                includeNull: true,
              },
              {
                name: 'workLoadType',
                key: 'id', 
                value: this.workLoads.map(o => o['id']).join(','),
                clause: 'whereIn',
              },
              {
                name: 'workLoadType',
                key: 'has_ambience', 
                value: 1,
              },
            ],
          },
        ],
      };

      if(this.selectedCampus && this.selectedCampus.campus_id_parent){
          delete payload.find_columns.campus_id;
          payload.filter_relations.push(
            {
              name: 'campus',
              key: 'campus_id_parent', 
              value: this.selectedCampus.campus_id_parent,
            }
          );
      }

      if(this.ambienceGridTypes.length > 0){

        payload.filter_relations[2].innerRelations.push(
          { 
            name: 'ambienceType',
            key: 'id', 
            value: this.ambienceGridTypes.map(o => o['id']).join(','),
            clause: 'whereIn',
          },
        );

      }

      if(this.onlyPending){
        payload.filter_relations[2].innerRelations.push(
          { 
            name: 'classes',
            key: 'ambience_id',
            value: 'isNull',
          },
        );
      }
        
      this.ActionFindGroups(payload)
        .then((res) => {

          res.data.forEach(item => {
            this.groups.push({
              key:          item.id,
              level:        0,
              id:           item.id,
              name:         item.name,
              time_course:  item.time_course,
              campus_id:    item.campus_id,
              course:       {id: item.course.name, name: item.course.name },
              children:     []
            });
          });
          
        }).finally(() => {
          this.loadingFilterGroups = false;
          this.panelGroup = 1;
        });
    },

    filterAmbiences(){
      
      this.ambiences = [];
      this.ambience = null;
      this.loadingFilterAmbiences = true;
      
      var payload = { 
        find_columns: {
          campus_id: this.campusesAmbiences,
          active: 1,
          not_available: 0,
        },
        filter_relations: [
          {
            name: 'type',
            key: 'id', 
            value: this.types.map(o => o['id']).join(','),
            clause: 'whereIn',
          },
          {
            name: 'floor',
            key: 'id', 
            value: this.floors.map(o => o['id']).join(','),
            clause: 'whereIn',
          },
        ],
        with: 'block,floor,type,campus',
      };

      if(this.categories.length > 0){
        payload.filter_relations.push({
          name: 'category',
          key: 'id', 
          value: this.categories.map(o => o['id']).join(','),
          clause: 'whereIn'
        });
      }

      if(this.blocks.length > 0){
        payload.filter_relations.push({
          name: 'block',
          key: 'id', 
          value: this.blocks.map(o => o['id']).join(','),
          clause: 'whereIn'
        });
      }

      this.ActionFindAmbiences(payload)
        .then((res) => {
          this.ambiences = res.data;
          this.setNameAmbiences();
        }).finally(() => {
          this.loadingFilterAmbiences = false;
          this.panelAmbience = 1;
        });
    },

    searchAmbiences(ev){
      this.showFormSearch = false;
      this.ambiences = ev;
      this.setNameAmbiences();
      this.panelAmbience = 1;
    },

    changeCampusGroup(item){

      this.campusesAmbiences = item.id;
      this.selectedCampus = item;

    },

    setNameAmbiences(){
      
      this.ambiences.forEach(item => {
        
        
        if(item.block && item.block.initials){
          item.name = 'Cap: ' + item.capacity + ' | ' + item.block.initials + ' | ' + (item.description ? (' (' + item.type.initials + ': ' + item.name + ' - ' + item.description + ')') : ' (' + item.type.initials + ': ' + item.name + ')');
        }else{
          item.name = 'Cap: ' + item.capacity + ' | ' + (item.description ? (' (' + item.type.initials + ': ' + item.name + ' - ' + item.description + ')') : ' (' + item.type.initials + ': ' + item.name + ')');
        }
        
        
      });
    },

    async fetchItemsGroup (item) {
        
      if(item.level == 0){
        await this.loadGrids(item);
      }else if(item.level == 1){
        await this.loadClasses(item);
      }
      
    },

    async loadGrids(item){

      var payload = {
        withStudentsJoin: true,
        find_columns: {
          group_id: item.id,
          has_ambience: 1,
          work_load_type_id: this.workLoads.map(o => o['id']).join(','),
        },
        filter_relations: [
          {
              name: 'workLoadType',
              key: 'has_ambience',
              value: 1,
          },
          {
            name: 'ambienceType',
            key: 'allow_ambience_link', 
            value: 1,
            includeNull: true
          }
        ],
        with: 'discipline,workLoadType,teacher,specificity,category'
      };

      if(this.ambienceGridTypes.length > 0){
        payload.find_columns.ambience_type_id = this.ambienceGridTypes.map(o => o['id']).join(',');
      }

      if(this.onlyPending){
        payload.filter_relations.push({

          name: 'classes',
          key: 'ambience_id',
          value: 'isNull',
        });
      }

      await this.ActionFindGrids(payload)
        .then((res) => {
            res.data.forEach(grid => {
              item.children.push({
                key:                    item.key + '|' + grid.id,
                group_id:               item.id,
                campus_id:              item.campus_id,
                work_load_type_id:      grid.work_load_type_id,
                ambience_type_id:       grid.ambience_type_id ? grid.ambience_type_id : grid.work_load_type.ambience_type_id,
                level:                  1,
                id:                     grid.id,
                name:                   this.getNameGrid(grid),
                students:               grid.students,
                students_forecast:      grid.students_forecast,
                students_join:          grid.students_join,
                students_join_forecast: grid.students_join_forecast,
                teacher:                grid.teacher ? { id: grid.teacher.id, name: grid.teacher.name } : null,
                specificity:            grid.specificity,
                subGroup:               grid.subGroup,
                category:               grid.category,
                join:                   grid.join,
                children:               []
              });

            });
        });
    },

    getNameGrid(grid){
      
      if(grid.group_number){
        return '(' + grid.work_load_type.initials + '=' + grid.work_load + ') - D' + grid.group_number + ' - ' + grid.discipline.name;
      }else{
        return '(' + grid.work_load_type.initials + '=' + grid.work_load + ') - ' + grid.discipline.name;
      }
      
      
    },

    loadJoin(grid){
        
      this.itemJoin = null;
      this.showJoin = true;
      this.loadingJoins = true;
      

      var payload = {
          id:     grid.join.join_id,
          with: "grids.join,grids.discipline,grids.group,grids.group.campus,status,grids.workLoadType,grids.workTimeDate",
          showAllCampuses: true
      }

      this.ActionGetGridJoin(payload)
        .then((res) => {
          this.itemJoin = res.data;
        }).finally(() => {
          this.loadingJoins = false;
        });
    },

    async loadClasses(item){

      var payload = {
        find_columns: {
          grid_id: item.id
        },
        with: 'hour,dayOfWeek'
      };
      
      await this.ActionFindClasses(payload)
        .then((res) => {
            res.data.forEach(classe => {
              item.children.push({
                key:              item.key + '|' + classe.id,
                group_id:         item.group_id,
                campus_id:        item.campus_id,
                students_join:    item.students_join,
                ambience_type_id: item.ambience_type_id,
                grid_id:          item.id,
                level:            2,
                id:               classe.id,
                name:             classe.day_of_week.name + ' | ' + classe.hour.name,
              });
            });
        });
    },

    selectGroup(item){
      
      if(!item){
        return;
      }

      this.itemSearhStart = {
        campus_id: item.campus_id,
      };
      
      var payload = {
        item_id: item.id,
        workLoads: this.workLoads.map(o => o['id']),
        ambienceGridTypes: this.ambienceGridTypes.map(o => o['id']),
        onlyPending: this.onlyPending,
      }

      this.level = -1;
      this.item_id = 0;

      switch (item.level) {
        case 0:
          payload.type = 'group';
          break;
        case 1:
          payload.type = 'grid';
          this.itemSearhStart.ambience_type_id        = item.ambience_type_id;
          this.itemSearhStart.students                = (this.config && this.config.capacity && this.config.capacity.forecast) ? item.students_join_forecast : item.students_join;
          this.itemSearhStart.students_join           = item.students_join;
          this.itemSearhStart.students_join_forecast  = item.students_join_forecast;
          break;
        case 2:
          payload.type = 'classe';
          break;
          default:
            return;
      }

      this.level = item.level;
      this.item_id = item.id;

      this.loadClassesGroup(payload, true);
      
    },

    loadClassesGroup(payload, force = false){
      
      if(!force && this.loadingSaving){
        return;
      }
      
      this.loadingSaving = true;
      var groupTemp = {};
      Object.assign(groupTemp,  this.group);
      this.group = null;
      
      this.ActionFindClassesOptimized(payload)
      .then((res) => {
        this.group = groupTemp;
        this.group.classes = res.data;
        this.itemSearhStart.classes = res.data;
      }).finally(() => {
        this.loadingSaving = false;
        this.forceRerender();
      });
    },

    selectAmbience(index){
      
      if(index != -1){
        let ambiences = this.ambiencesFiltered;
        this.ambience = ambiences[index];
        this.loadClassesAmbience(this.ambience);
      }else{
        this.ambience == null;
      }

    },

    loadClassesAmbience(ambience){
      
      if(this.loadingSaving || !ambience){
        return;
      }
      
      var payload = {
        type: 'ambience',
        item_id: ambience.id,
        onlyPending: this.onlyPending,
      };

      this.loadingSaving = true;
      var ambienceTemp = {};
      Object.assign(ambienceTemp,  this.ambience);
      this.ambience = null;
      
      this.ActionFindClassesOptimized(payload)
      .then((res) => {
        this.ambience = ambienceTemp;
        this.ambience.classes = res.data;
      }).finally(() => {
        this.loadingSaving = false;
        this.forceRerender();
      });
    },

    forceRerender () {
      
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });

    },

    clearFilterGroup(){
        
        this.campuses = null;
        this.shifts = [];
        this.workLoads = [];
        this.ambienceGridTypes = [];
        this.courses = [];
        this.group = null;
        this.groups = [];
        this.panelGroup = 0;
    },

    clearFilterAmbience(){
        
      this.categories = [];
      this.types = [];
      this.floors = [];
      this.blocks = [];
      this.ambiences = [];
      this.panelAmbience = 0;
      this.ambience = null;
      
  }
    
    

  }

}
